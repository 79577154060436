<template>
  <section id="CAREERS" class="bg-white">
    <div class="">
      <img class="mx-auto lg:block hidden w-[53%]" src="../assets/who_we_need/who_we_need.png"/>
      <img id="" class="mx-auto lg:hidden py-[5vh] w-[480px]" src="../assets/who_we_need/h5whoweneed.png"/>
      <div
          class="md:text-2xl text-md md:text-start text-center w-[80%] mx-auto text-[#999999] mb-[80px]">
        We build a better world with Information Technology. And as we arise in providing the global market of
        outstanding software development and innovative application services.
        We're looking for professionals who are willing to showcase their skills in accord to cater the needs
        of our valued international and local clients.
      </div>
      <div>
        <ul class="md:flex hidden justify-center items-center my-4 text-center text-2xl text-[#B7B7B7] xl:w-full w-[90%]  mx-auto max-w-[1150px] ] ">

          <li v-for="(item,index) in role_label" :key="'A' + index"
              class="w-full cursor-pointer py-2 relative tracking-wider " @click="setActiveRole(item)"
              :class="item === active_role ? 'font-bold text-[#FAAD14] activeJob ' : 'font-normal '">
            <div class="borderTop" :class="index === 0 ? 'borderDuloLeft' : index === 4 ? 'borderDuloRight' :''"></div>

            {{ item }}
          </li>
        </ul>
        <div
            class="md:border-x-[0px] md:border-t-[0px]  border-t-[1.5px] border-x-[1.5px] rounded-2xl w-[90%] mx-auto md:my-0 my-4">
          <div class="shadow-sm mb-1 md:hidden border-b-[2px] px-2 py-3 mx-auto space-y-2">
            <button
                v-for="(item, index) in role_label" :key="'B' + index"
                @click="setActiveRole(item)"
            >
              <div
                  class="cursor-pointer border-2 bg-transparent mx-1 rounded-full px-4 py-1 text-base transition duration-500 ease select-none tracking-wider focus:outline-0 active:outline-0 hover:outline-0"
                  :class="item === active_role ? 'border-[#FAAD14] text-[#FAAD14] font-bold bg-[#FAAD141A]/[.1]' : 'border-[#DFDFDF] text-[#666666] font-normal'"
              > {{ item }}
              </div>

            </button>
          </div>


          <TransitionGroup name="cards" tag="div"
                           class="md:grid hidden w-full xl:grid-cols-3 grid-cols-2 gap-6  rounded-2xl w-[80%]  mx-auto mb-[150px] max-w-[1150px] ">

            <template v-for="(itemUpper,index) in roleData">
              <template v-if="active_role === 'All'">
                <template v-for="(item,indexX) in itemUpper.details">
                  <template v-if="loadMore">
                    <CardsComp v-if="index < 3 && indexX < 3" v-bind:key="'C-' + index + '-' + indexX" :index="index" :indexX="indexX"
                               :itemUpper="itemUpper" :roleIcon="roleIcon" :item="item"
                               :showModalMethod="showModalMethod"/>
                  </template>
                  <template v-if="!loadMore">
                    <CardsComp v-bind:key="'D-' + index + '-' + indexX"  :index="index" :indexX="indexX" :itemUpper="itemUpper"
                               :roleIcon="roleIcon" :item="item" :showModalMethod="showModalMethod"/>
                  </template>
                </template>
              </template>
              <template v-if="itemUpper.role === active_role.toLowerCase()">
                <template v-for="(item,indexX) in itemUpper.details">
                  <template>
                    <CardsComp v-bind:key="'E' + indexX + index" :index="index" :indexX="indexX" :itemUpper="itemUpper"
                               :roleIcon="roleIcon" :item="item" :showModalMethod="showModalMethod"/>
                  </template>
                </template>
              </template>
            </template>
          </TransitionGroup>
          <TransitionGroup name="cards" tag="div" id="mobileJobPosting"
                           class="md:hidden grid  grid-cols-1 border-b-[5px] rounded-2xl mx-auto md:mb-[150px] mb-[-30px] max-h-[750px] overflow-y-scroll">
            <template v-for="(itemUpper,index) in roleData">
              <template v-if="active_role === 'All'">
                <template v-for="(item,indexX) in itemUpper.details">
                  <template>
                    <CardsCompH5 v-bind:key="'F-' + index + '-' + indexX" :indexX="indexX" :itemUpper="itemUpper" :roleIcon="roleIcon"
                                 :item="item" :showModalMethod="showModalMethod"/>
                  </template>
                </template>
              </template>
              <template v-if="itemUpper.role === active_role.toLowerCase()">
                <template v-for="(item,indexX) in itemUpper.details">
                  <template>
                    <CardsCompH5 v-bind:key="'G' + indexX + index" :indexX="indexX" :itemUpper="itemUpper"
                                 :roleIcon="roleIcon"
                                 :item="item" :showModalMethod="showModalMethod"/>
                  </template>
                </template>
              </template>
            </template>

          </TransitionGroup>
        </div>
        <div v-if="active_role === 'All'" class="md:flex hidden justify-center">
          <a v-if="loadMore" href="#CAREERS">
            <div
                @click="loadMoreModal"

                class="font-bold border-2 border-[#22539C] bg-transparent text-[#22539C] rounded-full px-10 py-4 text-2xl transition duration-500 ease select-none hover:bg-[#C5DCFF80]/[.5] focus:bg-[#22539C] focus:text-[#103060] "
            >
              Load More
            </div>
          </a>
          <a v-if="!loadMore" href="#CAREERS">
            <div
                @click="loadMoreModal"

                class="font-bold border-2 border-[#22539C] bg-transparent text-[#22539C] rounded-full px-10 py-4 text-2xl transition duration-500 ease select-none hover:bg-[#C5DCFF80]/[.5] focus:bg-[#22539C] focus:text-[#103060] "
            >
              Show Less
            </div>
          </a>
        </div>
        <!-- -->
        <div class="px-4 lg:my-[150px] my-[75px] flex flex-row flex-wrap justify-center items-center gap-4 mx-auto lg:!max-w-[50%]">
          <a href="https://www.facebook.com/SolidLeisureSolutionsInc.Official" target="_blank"
             class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center">
            <img class="w-[100px] md:w-[186px]" src="../assets/about_us/FB.png" alt="">
          </a>
          <a href="https://www.linkedin.com/company/solidleisure-solutions/" target="_blank"
             class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center">
            <img class="w-[100px] md:w-[186px]" src="../assets/about_us/Linkedin.png" alt="">
          </a>
          <a href="https://ph.indeed.com/cmp/Solid-Leisure" target="_blank"
             class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center">
            <img class="w-[100px] md:w-[186px]" src="../assets/about_us/Indeed.png" alt="">
          </a>
          <a href="https://www.jobstreet.com.ph/en/companies/161252493143697-solid-leisure-solutions-inc"
             target="_blank"
             class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center">
            <img class="w-[100px] md:w-[186px]" src="../assets/about_us/Jobstreet.png" alt="">
          </a>
          <a href="https://www.cakeresume.com/companies/solidleisure-solutions-inc?locale=zh-TW"
             target="_blank"
             class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex justify-center items-center">
            <img class="w-[100px] md:w-[186px]" src="../assets/about_us/CakeResume.png" alt="">
          </a>
        </div>
      </div>
    </div>
    <transition name="modal">
      <div v-if="showModal" class="modal-mask ">
        <div class="modal-wrapper z-[9999]">
          <div class="modal-container md:w-[65%] w-[90%] rounded-2xl relative">
            <img @click="hideModal" class="absolute right-5 cursor-pointer  md:block hidden w-[45px]"
                 src="../assets/who_we_need/eva_close-fill.png" alt="photo">
            <img @click="hideModal" class="absolute right-2 top-2 cursor-pointer md:hidden w-[35px]"
                 src="../assets/who_we_need/eva_close-fill.png" alt="photo">
            <div class="flex items-center md:text-3xl pb-2 text-xl text-[#3E5578]">
              <img class="md:block hidden w-[75px] mr-4"
                   :src="rolePosition === 'developer' ? roleIconWO_shadow.developer : rolePosition === 'engineer' ? roleIconWO_shadow.engineer : rolePosition === 'design' ? roleIconWO_shadow.designer : rolePosition === 'product' ? roleIconWO_shadow.product : '' "
                   alt="photo">

              <img class="md:hidden w-[55px]"
                   :src="rolePosition === 'developer' ? roleIconWO_shadow.developer : rolePosition === 'engineer' ? roleIconWO_shadow.engineer : rolePosition === 'design' ? roleIconWO_shadow.designer : rolePosition === 'product' ? roleIconWO_shadow.product : '' "
                   alt="photo">

              {{ modalInfo.position }}
            </div>
            <div class="modalContent max-h-[500px] overflow-y-scroll">
              <div class="w-[95%] mx-auto md:text-lg text-base space-y-6 my-6">
                <div class="font-bold ">Job Description</div>

                <ol class="list-decimal list-inside w-[95%] mx-auto space-y-2 text-[#222222]  text-sm">
                  <li v-for="item in modalInfo.jobDescription" v-bind:key="item.position">{{ item }}</li>
                </ol>
                <ol v-if="modalInfo.additionalDescription"
                    class="list-decimal list-inside w-[95%] mx-auto space-y-2 text-[#222222]  text-sm">
                  <div v-if="modalInfo.additionalDescription" class="font-bold  text-sm">

                    {{ modalInfo.additionalDescription.title }}
                  </div>
                  <li v-for="(item) in modalInfo.additionalDescription.details" v-bind:key="item.position">
                    {{ item }}
                  </li>
                </ol>
              </div>
              <div class="w-[95%] mx-auto md:text-lg text-base space-y-6 pb-12 ">

                <div class="font-bold ">Job Responsibilities</div>
                <ol class="list-decimal list-inside w-[95%] mx-auto space-y-2 text-[#222222] text-sm">
                  <li v-for="item in modalInfo.jobResponsibility" v-bind:key="item.position">{{ item }}</li>

                </ol>
              </div>
            </div>
            <div class="border-t-[.01px] border-white w-full border-down"></div>
            <div class="w-full flex justify-center pt-4">
              <a :href="'mailto:recruitment@solidleisure.com?subject='+modalInfo.position">
                <button
                    type="button"
                    class="font-semibold bg-[#22539C] text-white rounded-full px-6 py-2 text-base transition duration-500 ease select-none md:hover:bg-[#4A7BC6]/[.5] outline-none "
                >

                  Apply Now

                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>
<script>
import dev from "../assets/who_we_need/icn_devFilled_shadow.png"
import eng from "../assets/who_we_need/icn_engrFilled_shadow.png"
import design from "../assets/who_we_need/icn_designerFilled_shadow.png"
import prod from "../assets/who_we_need/icn_projmgmtFilled_shadow.png"


import dev_withOut from "../assets/who_we_need/icn_dev.png"
import eng_withOut from "../assets/who_we_need/icn_engr.png"
import design_withOut from "../assets/who_we_need/icn_designer.png"
import prod_withOut from "../assets/who_we_need/icn_projmgmt.png"


import CardsComp from "@/components/CardsComp"
import CardsCompH5 from "@/components/CardsCompH5"

export default {
  name: "WhoWeNeed",
  components: {
    CardsComp,
    CardsCompH5
  },
  data: function () {
    return {
      rolePosition: '',
      loadMore: true,
      showModal: false,
      role_label: [
        "All", "Product", "Developer", "Design", "Engineer"
      ],
      active_role: 'All',
      roleData: [
        {
          role: 'developer',
          details: [
            {
              position: "C++ Game Developer",
              experience: "Min 2 years",
              jobDescription: [
                "Bachelor's Degree in any field of studies.",
                "Minimum 5 years of C++ development foundation.",
                "Have some experience in server development.",
                "Understand multi-threading, network, TCP, UDP and database.",
                "Good project exposure from start to finish, which can be MFC or QT and other development projects.",
                "Proficient in using GIT, SVN, Visual Studio and other tools.",
                "Priority will be given to those with working experience in algorithms or large factories..",
                "Have a good work attitude and good communication."
              ],
              jobResponsibility: [
                "You are responsible for the development of Linux system server peripheral functions and daily assistance in troubleshooting.",
                "To work on projects and support functions under MFC or QT and other development projects."
              ]
            },
            {
              position: "Android Mobile Developer",
              experience: "Min 2 years",
              jobDescription: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "4 years of experience in UX and UE interaction design, with experience in Android and iOS platforms.",
                "Experience in interactive design on desktop and mobile; proficient in using Sketch/Figma/Adobe XD/Axure etc.",
                "Excellent problem-solving skills and familiarity with technical constraints and limitations.",
                "Have excellent innovation and learning skills; excellent communication skills."
              ],
              jobResponsibility: [
                "Design, develop, and implement Android applications.",
                "Strong troubleshooting skills and system maintenance ability.",
                "Analyze and optimize UI and infrastructure application code for quality, efficiency, and performance.",
                "Be able to learn new skills in response to complicated problems.",
                "Communicate with QA, back-end engineers, project manager, and product manager positively to finish given projects on time.",
                "Build reusable Android software components for interfacing with our back-end platforms"
              ]
            },
            {
              position: "Front End Developer - VueJS",
              experience: "Min 3 years",
              jobDescription: [
                "Bachelor's Degree in any field of studies.",
                "More than 5 years of experience in WEB development, and cooperating with the back end to complete WebAPI and WebSocket data docking.",
                "Project experience in developing web front-end applications, with experience in architecture reconstruction and packaging separation.",
                "Proficient in JavaScript, HTML, CSS;",
                "Familiar with mainstream front-end frameworks such as Vue.js and Node.js, and proficient in one of the front-end frameworks Vue/Angular/React.",
                "Have good coding habits and pay attention to norms and standards.",
                "Have good communication skills and a team player."
              ],
              jobResponsibility: [
                "Responsible for the development of game functions and the maintenance of existing game functions.",
                "Cooperate with operators to discuss design documents, avoid design loopholes, and complete logic implementation.",
                "Ensure game development time and quality, and ensure that the project development cycle is controllable."
              ]
            },
            {
              position: "AI Media Developer",
              experience: "Min 2 years",
              jobDescription: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "Proficient in C++ and have 2 + years or more of Linux platform live broadcast and on-demand server development experience.",
                "Proficient in RTMP, RTSP, HTTP-FLV, and other streaming media network transmission protocols.",
                "Proficient in audio and video coding and decoding technology.",
                "Familiar with streaming media transcoding and packaging process.",
                "Familiar with common streaming media packaging formats, MP4, FLV, TS, etc.",
                "Proficient in network programming.",
                "Have good communication skills and teamwork spirit."
              ],
              jobResponsibility: [
                "Participate in the server development of live video and on-demand services.",
                "Participate in the optimization of the media server architecture.",
                "According to the assignment of tasks, complete the corresponding code writing and document writing."
              ]
            },
            {
              position: "AI Image Developer",
              experience: "Min 2 years",
              jobDescription: [
                "With more than 2+ years of experience in video and image processing development, those with relevant experience in image processing using deep learning are preferred.",
                "Familiar with various basic computer vision and image feature extraction algorithms, project experience in image tracking and recognition is preferred.",
                "Able to independently complete the design and coding of related algorithms.",
                "Master machine learning, deep learning and other related knowledge, familiar with at least one deep learning training tool; familiar with common deep learning models (tensorflow, caffe).",
                "Proficient in C/C++ and Python programming, and have a deeper understanding of OpenCV.",
                "Have a strong ability to analyze and solve problems.",
                "Serious and responsible work, rigorous and meticulous, with a good sense of innovation, communication skills and teamwork spirit."
              ],
              jobResponsibility: [
                "Responsible for algorithm design, implementation and verification of video image processing, image recognition, target detection and analysis.",
                "Optimize the recognition engine, improve the recognition efficiency and success rate.",
                "Write the application interface for the image recognition algorithm program and integrate it into the application system, and conduct joint debugging, testing and maintenance with application developers.",
                "Responsible for the design and development of image algorithms, including but not limited to image semantic segmentation, object detection, image classification, image enhancement, automatic annotation, video analysis, image reasoning, network structure search, etc.",
                "Responsible for the research and development of deep learning related technologies and systems, including model training and reasoning acceleration, model quantization, model compression, etc., optimizing the recognition engine, improving the recognition efficiency and success rate.",
                "Assist in the packaging, deployment and optimization of algorithms on mobile and cloud systems to ensure stable and efficient algorithm services.",
                "Responsible for the preparation and archiving of technical documentation specifications."
              ]
            },
            {
              position: "HTML5 Game Developer ",
              experience: "Min 2 years",
              jobDescription: [
                "Familiar with JavaScript and TypeScript language, master the most basic principles of JavaScript.",
                "Use TypeScript coding for at least half a year.",
                "Familiar with Egret or LayaAir game engine, must have more than 1 medium and large game development case.",
                "Familiar with engine custom controls, including upper-level UI controls and lower-level self-drawing controls.",
                "Familiar with and use common animations such as frame animation, attribute animation, Spine animation, and keel animation.",
                "Familiar with HTTP, WebSocket, TCP protocol, and the packet and unpack of protocol data stream.",
                "Ability to independently build and develop complete medium and large complex H5 applications and games.",
                "Familiar with software development design patterns.",
                "Understand H5 Dom API."
              ],
              jobResponsibility: [
                "Responsible for the development of the company's H5 game.",
                "Responsible for the development of auxiliary tools such as editors according to the needs of game functions.",
                "Responsible for communicating with planning and back-end developers, completing logic and interaction design, etc."
              ]
            },
            {
              position: "iOS Developer",
              experience: "Min 2 years",
              jobDescription: [
                "Bachelor's degree or above",
                "Need 2-5 years of software project management experience.",
                "Have good communication skills and be familiar with the software project process.",
                "Good document writing ability and ability to withstand pressure.",
                "With rich experience in technical team work and cooperation, excellent historical performance is preferred.",
                "Possess excellent business promotion ability and team management ability, excellent ability to withstand pressure."

              ],
              jobResponsibility: [
                "Responsible for the project management of the platform system, maintain efficient communication with partners and business departments, change according to needs and deliver tasks on time.",
                "Responsible for cross-group or cross-department demand communication and work development.",
                "Establish and maintain project management system, project kanban and other project progress display tools.",
                "Find and track and solve project problems in time, and effectively manage project risks."
              ]
            },
            {
              position: "Senior Java Developer",
              experience: "Min  3 years",
              jobDescription: [
                "Bachelor's Degree in any field of studies.",
                "7+ years of software design and development experience, large-scale web system research and development is preferred.",
                "Must have worked on 1-2 projects full cycle, able to work on new features, enhancement, and production support; a year as a senior developer.",
                "Proficient in J2EE, Spring, Spring MVC, SpringBoot.",
                "Strong learning ability, excellent logical thinking ability.",
                "Good communication and teamwork skills."
              ],
              jobResponsibility: [
                "Develop, deploy, and maintain applications on J2EE.",
                "Candidate must have a deep understanding of the basic theory of databases, and be able to use Oracle or MySQL proficiently.",
                "With a good understanding of HTML, CSS, and JS, familiarity with NodeJS is preferred 5.",
                "Proficiency in Linux system, master common commands."
              ]
            },
            {
              position: "JAVA Big Data Engineer",
              experience: "Min  3 years",
              jobDescription: [
                "Bachelor's degree or above, more than 3 years of software design and development experience, large-scale web system research and development is preferred.",
                "Be able to apply J2EE and other web development technologies proficiently, and be proficient in Spring, SpringMVC, SpringBoot.",
                "Have a deep understanding of the basic theory of databases, and be able to use oracle or mysql proficiently.",
                "Understand html, css, js, familiar with nodejs is preferred 5. Proficiency in Linux system, master common commands.",
                "Strong learning ability, excellent logical thinking ability 6. Good communication and teamwork skills."

              ],
              jobResponsibility: [
                "Responsible for business needs, design, research and development.",
                "Continuously improve the business logic, system architecture, core technology, etc. of the website.",
                "Ensure system high performance, high availability and high scalability.",
                "Responsible for performance analysis and improvement of related modules to ensure system performance and stability."
              ]
            },
            {
              position: "Senior Cocos Creator Engineer",
              experience: "Min  3 years",
              jobDescription: [
                "Bachelor's Degree in any field of studies.",
                "4-5 years of hands-on coding in Cocos Creator, familiar with the features of various versions, as well as TypeScript or JavaScript language.",
                "More than three years of experience in Cocos game development (bonus points for successful projects online).",
                "Proficient in commonly used design patterns, algorithms, mathematical methods, and optimization in game development.",
                "Familiar with and capable of using common animations such as frame animation, property animation, Spine animation, and DragonBones animation",
                "Familiar with HTTP, WebSocket, and TCP protocols, as well as the packaging and unpacking of protocol data streams.",
                "Familiar with software development design patterns.",
                "Understanding of H5 DOM API.",
                "Understanding of the Cocos Creator engine extension process.",
                "Familiar with Cocos Creator's native and web architecture principles."

              ],
              jobResponsibility: [
                "Responsible for the development of the company's H5 games.",
                "Responsible for the development of editors and other auxiliary tools according to the requirements of game functions.",
                "Communicate with planners and backend developers to complete logic and interaction design, etc."
              ]
            },
          ],
        },
        {
          role: 'engineer',
          details: [
            {
              position: "Applications Test Engineer",
              experience: "Min 2 years",
              jobDescription: [
                "Bachelor's Degree in any field of studies.",
                "Minimum 7 years of experience as QA, Software Tester, or Application/ Game Tester.",
                "Knowledge or working capacity in UAT, Functional Testing, Performance Testing, or full-swing game application testing.",
                "Have good communication skills and be a team player."
              ],
              jobResponsibility: [
                "Responsible for the overall testing of game products, including formulating test plans, designing test cases, and performing game function tests.",
                "Locate quality problems, promote development and solve quality problems continuously improve test content, and ensure product quality.",
                "Compile test reports, effectively classify and summarize.",
                "Mainly responsible for the function test, protocol & interface test of the PCH5/MH5/APP client and server in the project."
              ]
            },
            {
              position: "Network Engineer",
              experience: "Min 2 years",
              jobDescription: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "4 years of experience in resolving and providing solutions for network requirements, participating in business architecture optimization, disaster recovery, application performance, and other projects to ensure data and business security.",
                "Responsible for organizing and writing comprehensive software engineering documents, proficient in using swift development language and Apple store online process.",
                "Responsible for the coding implementation of core business and technical components.",
                "CCNA or other network related certification is a plus.",
                "Able to handle and solve problems in projects independently."
              ],
              jobResponsibility: [
                "Responsible for the communication of network requirements, participating in business architecture optimization, disaster recovery optimization, application performance optimization, and other projects to ensure data and business security, and ensure the control and improvement of the deployment structure of business products, IDC distribution, HA scheme, etc. from the network.",
                "Design, develop, and maintain iPhone client software according to requirements.",
                "Review the implementation plan submitted by front-line engineers and put forward guiding suggestions.",
                "As a second-line engineer, lead the preparation of major network adjustments and network optimization plans, and guide the first-line engineers to implement them.",
                "Guide the front-line engineers in compiling network technical service regulations to ensure the standardization of on-site services.",
                "Participate in the handling of major network failures, conduct detailed analysis of common problems and recurring failures during network operation and maintenance, and submit corresponding problem solutions.",
                "Routine inspection and continuous optimization of core network and security equipment.\n",
                "Complete other tasks from superiors."
              ]
            },
            {
              position: "System Engineer",
              experience: "Min  3 years",
              jobDescription: [
                "Major in computer or it, with a certain level of English reading and communication skills.",
                "Proficient in the operation and maintenance of mail peripheral systems and projects, including but not limited to mail gateways, mail archiving, load balancing, encryption verification, third-party packages used in the software system, and system interfaces called by other application systems, etc.",
                "Complete configuration adjustment, strategy adjustment, structure adjustment and optimization, update and upgrade, troubleshooting, special needs processing, etc.",
                "Proficient in system operation and maintenance scripting languages, such as powershell, batch processing, etc., can be proficient in scripting to complete automatic maintenance of AD, exchange and other servers.",
                "Have MCSE, RHCE and other certifications preferred, familiar with CEPH, OPENSTACK, K8S and other open source solutions are preferred.",
                "Experience in script development languages, such as PYTHON is preferred.",
                "Familiar with the deployment and application of VMWARE, CTRIX or other open source virtualization systems, proficient in using the VMWARE system, able to quickly complete the construction of the server environment, experience in VMWARE NSX is preferred.",
              ],
              jobResponsibility: [
                "Design, develop and maintain IPhone client software according to requirements.",
                "Responsible for organizing and writing comprehensive software engineering documents, proficient in using swift development language and Apple store online process.",
                "Responsible for the coding implementation of core business and technical components.",
                "Able to handle and solve problems in projects independently.",
                "Architecture design and core development of iOS app.",
                "Responsible for communication with product personnel and coordination of other related resources.",
              ]
            },
            {
              position: "IT Helpdesk Engineer",
              experience: "Min 1 year",
              jobDescription: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "At least 2 years of working experience in the related field is required for this position.",
                "Applicant must be willing to do field work and support.",
                "Must be knowledgeable and experienced with the different roles of servers such as active directory, attendance server, DHCP, and print server. VDI and Exchange server.",
                "Must be knowledgeable and experienced with different types of Windows and Apple operating systems, Windows Server, and Windows PE.",
                "Must be knowledgeable with computer systems and network topology and troubleshooting.",
                "Strong troubleshooting and analytical skills.",
                "Can work with minimum supervision and team player.",
                "Willing to work overtime"
              ],
              jobResponsibility: [
                "To provide quick IT resolutions, construct reasonable structure, reasonable cost, and strong scalability of its services.",
                "Responsible for the company's IT system construction and selection, management, and maintenance, to ensure the stable operation of infrastructure services and business.",
                "Responsible for the operation maintenance and monitoring of the company's AD, EXCHANGE, SQL, and other servers.",
                "Compile company IT-related documents, and system training materials, provide corresponding training, etc.",
                "Efficient learning ability can quickly expand personal knowledge and improve IT skills according to the company's business development."
              ]
            },
            {
              position: "Linux System Engineer",
              experience: "Min  3 years",
              jobDescription: [

                "More than 3 years of relevant work experience in Internet industry operation and maintenance, monitoring, and development.",
                "Understand the working principle of Linux operating system, and be proficient in using server systems such as CentOS and Ubuntu.",
                "Familiar with network principles, TCP/IP protocol, and have a certain ability to analyze network problems under Linux.",
                "Familiar with ip, tc, iptables and other commands under Linux, and can design iptables rules based on requirements.",
                "Familiar with at least one language such as shell/python/GO, and can write simple tool scripts based on requirements.",
                "Familiar with Zabbix/Prometheus/Open falcon/Grafana/ELK, etc., relevant work experience is preferred.",
                "Good sense of service, serious work attitude, courage and carefulness in doing things, able to continuously explore and learn new areas.",
                "Have a strong sense of responsibility, provide 7*24 hours of service and technical support, and respond to and deal with problems in a timely manner.",


              ],
              additionalDescription: {
                title: "Corresponding technical line:",
                details: [
                  "Basic knowledge: Linux, DNS, PXE/Cobbler, batch deployment.",
                  "Package management tools: YUM, RPM, rpmbuild, etc.",
                  "Automation tools: SaltStack, Ansible",
                  "Diagnosis and troubleshooting: tcpdump/wireshark",
                  "Load balancing: NetScaler, Nginx, LVS, Haproxy",
                  "Message middleware: RabbitMQ, Kafka",
                  "Cache middleware: Redis, Redis Cluster, Memcache",
                  "Other tools: rsync, NFS, FTP, etc."
                ]
              },
              jobResponsibility: [
                "Provide 2nd to 3rd Level of support for desktop and apple computers, systems and software related problem and network problem.",
                "Computer deployment, hardware and peripheral installation such as printer, desktop and apple computers, biometric devices, laptop video card, memory and etc.",
                "2nd level management of different server roles such as active directly, attendance server, DHCP server, print server, anti-virus, computer.",
                "VDI creation and user assignment, virtual application creation and VDI troubleshooting.",
                "Create and delete user accounts for new and existing employees such as Domain account , email accounts, company messaging application, biometric registration and permission and VDI account.",
                "Able to manage exchange server mail policy, security and distribution group creation , mailbox storage, etc.",
                "Active Directory and company messaging application structure , user and policy adjustments based on managements request.",
                "Operating system and software deployment, company default security application deployment, maintenance and update.",
                "Received and solve user request, escalate user concern using detailed escalation procedures.",
                "Coordinate with different IT groups and department to satisfy user s IT concern.",
                "Attend users concern through IT ticketing system , email, call, text and company messaging.",
                "document and update standard operation procedures related to fulfillment of technical request and analyze how to eliminate the root cause.",
                "Follow and enforce the corporate policies and procedures, maintaining and enforcing the highest standards of security as set out by the company.",
                "Conduct IT security training for new and existing employee.",
              ]
            },
            {
              position: "Site Reliability Engineer",
              experience: "Min 2 years",
              jobDescription: [
                "College degree or above, computer-related major, 2 years or more of Linux operation and maintenance work experience, and more than 100 Linux server operation and maintenance experience.",
                "Have actual business operation and maintenance experience, such as WEB, game and other business types of maintenance, have actual use experience of nginx, it is best to understand high availability at the business level.",
                "Have basic network knowledge, such as TCP protocol, http, dns, etc., CCNA or CCNP certification is better.",
                "Familiar with mainstream products and suppliers in the CDN industry, have practical experience in CDN use, and be able to deal with common CDN related issues.",
                "Familiar with the basic structure of Internet products, experience in Internet product development or operation and maintenance is preferred.",
                "Have a strong sense of responsibility, provide 7*24 hours of service and technical support, and respond to and deal with problems in a timely manner.",


              ],
              additionalDescription: {
                title: "Corresponding technical line:",
                details: [
                  "Basic knowledge: Linux, DNS, DHCP, TCP/IP, tomcat, http",
                  "Package management tools: YUM, RPM, etc.",
                  "Automation tools: SaltStack, Ansible, jenkins, github",
                  "Diagnosis and troubleshooting: tcpdump/wireshark",
                  "Load balancing: NetScaler, Nginx, LVS, Haproxy",
                  "Other tools: rsync, NFS, CDN,"
                ]
              },
              jobResponsibility: [
                "Responsible for the online and offline of the hardware server, daily maintenance, performance tuning, etc.",
                "Responsible for the maintenance and optimization of operation and maintenance systems such as dns, confluence, ntp, yum, salt-stack, etc.",
                "Responsible for the operation and maintenance and optimization of middleware systems such as rabbitmq and redis clusters.",
                "Responsible for the daily maintenance of the virtual machine management platform openstack or vsphere, and provide corresponding machines according to requirements.",
                "Create related business systems as required, such as zabbix, prometheus, grafana, jira, ELK, etc.",
              ]
            },
            {
              position: "DataBase Administrator",
              experience: "Min  3 years",
              jobDescription: [
                " More than 3 years of experience in large-scale database maintenance.",
                " Familiar with the working principle of Linux operating system and proficient in using CentOS operating system.",
                " Familiar with at least one language such as shell/python, and can use scripts to complete related tasks.",
                " Familiar with Oracle and MySQL installation backup and troubleshooting.",
                " Familiar with database optimization technology.",
                " Familiar with database patch maintenance, including Oracle upgrade PSU, One-off patch, MySQL major version upgrade, OEM upgrade, etc.",
                " Familiar with the construction and maintenance of high-availability technologies for databases, such as Oracle using DG and MySQL using master-slave replication technology.",
                " Familiar with Oracle Goldengate.",
                " Master database monitoring tools, including Zabbix, OEM.",
                " Good working attitude, strong sense of responsibility, rigorous thinking and serious work.",
              ],
              jobResponsibility: [
                "Responsible for the maintenance of the online website business to ensure that the online business can operate normally 7*24.",
                "Cooperate with the development for the deployment, launch and subsequent maintenance of new projects.",
                "Deeply understand the architecture of the product, discover and solve hidden troubles and performance bottlenecks, and participate in projects such as architecture optimization, disaster tolerance optimization, and performance optimization.",
                "Cooperate with the product to carry out daily upgrades and releases, and optimize the upgrade process to improve the efficiency of upgrades and releases.",
                "Cooperate with the formulation of related operation and maintenance procedures to ensure business stability, security and standardization.",
              ]
            },
            {
              position: "Linux Specialist",
              experience: "Min  1 year",
              jobDescription: [

                "More than 1 year of relevant work experience in Internet industry operation maintenance and monitoring.",
                "Understand the working principle of Linux operating systems, such as Cebtos, Ubuntu, etc.",
                "Familiar with network principles, TCP/IP protocol, DNS and other services. Have a certain ability to analyze network problems under Linux. CCNA and other network certifications will be preferred.",
                "Familiar with common Linux commands, such as checking cpu, memory, process, ping, tracert to be able to determine if the service is normal.",
                "Basic understanding of web sites, such as access principles, domain name resolution, certificates, etc.",
                "Service oriented. Able to take on challenges. Keen to details. Cautious on doing things. Able to learn continuously and explore new ideas.",
                "Strong sense of responsibility and need on call for urgent case.",
                "Willing to work on shifting schedule.",
              ],
              jobResponsibility: [
                "Undertake the SLA of the responsible database, and design and optimize the existing architecture.",
                "Responsible for daily upgrade tasks, including temporary upgrades, fixed maintenance window upgrades, and collaborative development to check data-related issues.",
                "Responsible for daily online database alarm handling and fault recovery.",
                "To build a new environment, evaluate the space and performance of the new environment, coordinate with the development, operation and maintenance personnel, and make a reasonable plan for the table structure.",
                "Responsible for routine maintenance operations, including offline script deployment, historical data offline, garbage table cleaning, table defragmentation, disk space increase, lvm maintenance, etc.",
                "Output database maintenance standard operating documents and database-related technology sharing.",
                "Responsible for database migration, including migration tasks caused by performance that cannot meet requirements and other business factors.",
                "Responsible for routine database machine room switching, verifying the high availability of the database.",
                "Responsible for database patch maintenance, including Oracle upgrade PSU, One-off patch, MySQL major version upgrade, OEM upgrade, etc.",
                "Construction and maintenance of high-availability technologies for existing databases, such as Oracle using DG, and MySQL using master-slave replication technology.",
                "Maintain online database backups, use RMAN technology to perform full and incremental backups of database files and archives, and maintain database flashback database functions.",
                "Monitor and maintain the online OGG environment.",
                "Maintain online big data, including troubleshooting, missing data refill, and new table operations.",
                "Optimize online database, including operating system configuration, database parameters and SQL optimization.",
                "Maintenance operations for online database monitoring, including Zabbix, PMM, OEM, Grafana monitoring.",
                "Maintain database automation tasks, such as daily data retrieval."
              ],

            },
            {
              position: "K8S Engineer",
              experience: "Min 2 years",
              jobDescription: [
                "Proficient in Linux, data computer networks, operating systems, or related knowledge.",
                "Familiar with or proficient in common components or frameworks involved in application architecture, including but not limited to Nginx, Keepalived, Redis, Elasticsearch, Haproxy, etc.",
                "Proficient in Kubernetes, proficient in parameter tuning of Kubernetes components.",
                "Familiar with deployment, configuration, and optimization of Prometheus, AlertManager, Grafana, etc.",
                "Proficient in the deployment, operation, and tuning of various third-party components in the Kubernetes cluster.",
                "Proficient in Calico network components, with CCNP/HCNP or higher certificate is preferred.",
                "It is best to have experience in operation and maintenance development, and be able to use Python/Golang to complete daily related tasks.",
              ],
              jobResponsibility: [
                "Responsible for the upgrade and release of online works.",
                "Daily operation and maintenance work processing, such as domain name purchase & configuration, certificate purchase & configuration, black and white list adjustment, domain name resolution, etc.",
                "As a docking window between products and operation and maintenance, accept user needs and deal with them, if they cannot be dealt with, upgrade to the second-line engineer in time.",
                "Continuously output relevant SOPs during work, so that follow-up personnel can carry out standard operations in accordance with relevant SOPs.",
                "Deal with daily online faults, such as domain name access exceptions, certificate exceptions, and so on.",
              ]
            },
            {
              position: "Site Reliability Engineer",
              experience: "Min 2 years",
              jobDescription: [
                "College degree or above, computer-related major, 2 years or more of Linux operation and maintenance work experience, and more than 100 Linux server operation and maintenance experience.",
                "Have actual business operation and maintenance experience, such as WEB, game and other business types of maintenance, have actual use experience of nginx, it is best to understand high availability at the business level.",
                "Have basic network knowledge, such as TCP protocol, http, dns, etc., CCNA or CCNP certification is better.",
                "Familiar with mainstream products and suppliers in the CDN industry, have practical experience in CDN use, and be able to deal with common CDN related issues.",
                "Familiar with the basic structure of Internet products, experience in Internet product development or operation and maintenance is preferred.",
                "Have a strong sense of responsibility, provide 7*24 hours of service and technical support, and respond to and deal with problems in a timely manner.",

              ],
              additionalDescription: {
                title: "Corresponding technical line",
                details: [
                  "Basic knowledge: Linux, DNS, DHCP, TCP/IP, tomcat, http",
                  "Package management tools: YUM, RPM, etc.",
                  "Automation tools: SaltStack, Ansible, jenkins, github",
                  "Diagnosis and troubleshooting: tcpdump/wireshark",
                  "Load balancing: NetScaler, Nginx, LVS, Haproxy",
                  "Other tools: rsync, NFS, CDN,",
                ]
              },
              jobResponsibility: [
                "Responsible for online Kubernetes cluster operation and maintenance, performance tuning, fault handling, etc.",
                "Responsible for monitoring and alarming various online business systems, including but not limited to the maintenance of Kube-Prometheus-stack.",
                "Responsible for the maintenance and troubleshooting of cluster management tools such as Rancher.",
                "Responsible for Ceph storage system maintenance, performance tuning, fault handling, etc.",
                "Participate in the design and optimization of application service system deployment, monitoring, strategy, and implementation.",
                "Responsible for the preparation of relevant documents for the application system, etc.",
              ]
            },
          ],
        }, {
          role: 'design',
          details: [
            {
              position: "Visual Designer",
              experience: "Min 2 years",
              jobDescription: [
                "Collaborate with product division to define and implement innovative solutions for the product direction,including but not limited to UI (H5, PC), Logo, main activity page, etc.",
                "Obey work arrangements, according quality requirements and complete tasks on time.",
                "Strong ability to withstand pressure, able to complete the project independently and work with others to completed the project outcome.",
                "Familiar with basic front-end knowledge, able to work independently and closely with with product manager, ensure the delivery of high-quality designs through proper provisioning of UI assets and detailed quality checks.",
                "High responsibilities on newly design trends, able to apply what they have learned and share with the team.",
                "Excellent team-building, high coordination and strong communication skills.",

              ],
              jobResponsibility: [
                "Responsible for the upgrade and release of online works.",
                "Daily operation and maintenance work processing, such as domain name purchase & configuration, certificate purchase & configuration, black and white list adjustment, domain name resolution, etc.",
                "As a docking window between products and operation and maintenance, accept user needs and deal with them, if they cannot be dealt with, upgrade to the second-line engineer in time.",
                "Continuously output relevant SOPs during work, so that follow-up personnel can carry out standard operations in accordance with relevant SOPs.",
                "Deal with daily online faults, such as domain name access exceptions, certificate exceptions, and so on.",
              ]
            },
            {
              position: "Animation designer",
              experience: "Min 2 years",
              jobDescription: [

                "College degree or above, major in design field for more than 2 years experienced in Website / Mobile App / Digital Design.",
                "Proficient in Figma (main), Sketch, Photoshop, Illustrator or other related user experience design tool.",
                "Preferable good at a variety of design styles, illustrators.",
                "Participated in large-scale project design work.",
                "Preferable Filipino Chinese or fluent in mandarin language.",

              ],
              jobResponsibility: [
                "Design dynamic visual effects based on product functions or existing company elements.",
                "Enhance the dynamic interactive experience of the product, as well as the upgrade and revision of dynamic elements.",
                "Make dynamic material or style templates based on user scenarios.",
              ]
            },
            {
              position: "Interaction Designer",
              experience: "Min 2 years",
              jobDescription: [

                "Responsible for the interactive design work of mobile, PC desktop, and app.",
                "Responsible for the interactive design work of mobile, PC desktop, and app.",
                "Cooperate with the product manager to complete the entire process (prototype drawing/flow chart).",
                "Optimizing the product independently and collaborating with the other teams to ensure project completion.",
                "Complete the improvement of interactive behavior and functions, and improve the overall user experience of the product.",

              ],
              jobResponsibility: [

                "More than 2 year of experience, majors in animation, art, and visual design are preferred.",
                "Have creative ability and solid animation skills.",
                "Can produce smooth, natural and practical motion effects.",
                "Proficient in AE, C4D animation production experience is preferred.",
                "Applicants must provide a collection of recent works.",

              ]
            },
            {
              position: "UI Designer",
              experience: "Min 2 years",
              jobDescription: [

                "More than 2 years of UI design experience in the game industry, familiar with the design standards of Android, IOS, and H5.",
                "Love design, actively pay attention to industry UI design trends, learn and practice methods to improve design capabilities.",
                "Have experience in hand-painting, special effects, and animation design.",
                "Excellent team consciousness, strong self-driving force, and attention to design details.",
                "There is game UI, game animation phase 𣯑 experience are all related to games.",

              ],
              jobResponsibility: [

                "Responsible for the UI design of the team project, is active and innovative, pays full attention to product design, and has a strong sense of promoting the realization of UI details.",
                "Pay attention to user experience and interaction design, and actively think about the optimization points of product experience improvement in the process of project participation.",
                "Possess excellent multi-end design capabilities, and those with experience are better, and provide support for the multi-end design work of the project.",
                "Have strong learning ability and be able to take an active role in different design tasks, such as motion design. Experience in motion design is preferred.",
              ]
            },
          ],
        }, {
          role: 'product',
          details: [
            {
              position: "Product Planning Specialist",
              experience: "Min  3 years",
              jobDescription: [
                "Bachelor's degree or above (higher-level education is preferred), familiar with product research and R&D processes, with more than 3 years of product design and operation experience, and able to propose corresponding product solutions according to the project background.",
                "Have strong online product design capabilities, and be able to manage and control projects smoothly.",
                "It requires a strong subjective initiative, a sense of responsibility, caution and carefulness, strong logic, and at the same time the perseverance to be able to settle down and deal with simpler work needs for a longer time.",
                "Ability to quickly integrate into the team and run through the cooperation between departments.",
                "Have certain data analysis ability, industry experience is preferred.",
              ],
              jobResponsibility: [
                "Carry out product function planning, scheme design, project landing and acceptance according to product strategy, market research and product status.",
                "Develop product version update and operation plans to continuously improve product performance.",
                "Analyze product data, mine user needs, and continuously adjust and optimize products.",
                "Responsible for related products and work closely with personnel from various departments to ensure the smooth progress of product research and development.",
                "Research competitors’ product iteration methods and content, discover the steps and directions of competitors’ product development, learn from and optimize their own games.",
              ]
            },
            {
              position: " Project Management Specialist",
              experience: "Min  3 years",
              jobDescription: [

                "Bachelor's degree or above.",
                "Need 3-5 years of software project management experience.",
                "Have good communication skills and be familiar with the software project process.",
                "Good document writing ability and ability to withstand pressure.",
                "With rich experience in technical team work and cooperation, excellent historical performance is preferred.",
                "Possess excellent business promotion ability and team management ability, excellent ability to withstand pressure.",


              ],
              additionalDescription: {
                title: "Special Priorities:",
                details: [
                  "Have experience in managing a team of 5 or more people.",
                  "Have internet-related work.",
                  "Those with gaming experience.",
                ]
              },
              jobResponsibility: [
                "Responsible for the project management of the platform system, maintain efficient communication with partners and business departments, change according to needs and deliver tasks on time.",
                "Responsible for cross-group or cross-department demand communication and work development.",
                "Establish and maintain project management system, project kanban and other project progress display tools.",
                "Find and track and solve project problems in time, and effectively manage project risks.",
              ]
            },
            {
              position: "Software Product Manager",
              experience: "Min  3 years",
              jobDescription: [
                "Bachelor's Degree in any field of studies.",
                "Must have 6-8 years in the Mobile /E-commerce/Fintech Industry as Product Owner / Product Founder or Product Manager.",
                "Proven track record in managing the product development lifecycle.",
                "With a product portfolio is a must-have.",
                "Language Requirement: Mandarin is mandatory."
              ],
              jobResponsibility: [
                "The product manager defines the application/product vision and strategy.",
                "Manages the full product development lifecycle.",
                "Provide clear recommendations from market research, benchmarks, and application data to help define and prioritize the backlog, to continuously improve features.",
                "Test ongoing tasks and ensure that everything is on schedule.",
                "Conducts market research and competitive analysis.",
                "Manages the project budget and timeline.",
                "Monitors in-project metrics and KPI.",
                "Collaborates with other teams and functions of the company.",
                "Communicate with the stakeholders.",
                "Manages the application soft-launch, launch.",
                "Manages the update and maintenance of the application.",
                "Identifies and implements monetization strategies.",
                "Collects and analyses player feedback and analytics.",
                "Ensures the game's quality and success in the marketplace."
              ]
            },
            {
              position: "Project Manager",
              experience: "Min  3 years",
              jobDescription: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering)",
                "With a minimum of 4 years of software project management experience.",
                "With rich experience in technical teamwork and cooperation.",
                "Have good communication skills and be familiar with the software project process.",
                "Good document writing ability and ability to withstand pressure."
              ],
              jobResponsibility: [
                "Responsible for the project management of the platform system, maintaining efficient communication with partners and business departments, changing according to needs, and delivering tasks on time.",
                "Responsible for cross-group or cross-department demand communication and work development.",
                "Establish and maintain project management system, project kanban, and other project progress display tools.",
                "Find and track and solve project problems in time, and effectively manage project risks.\n"
              ]
            }
          ],
        }
      ],


      modalInfo: {},
      roleIcon: {
        developer: dev,
        engineer: eng,
        designer: design,
        product: prod
      },
      roleIconWO_shadow: {
        developer: dev_withOut,
        engineer: eng_withOut,
        designer: design_withOut,
        product: prod_withOut
      },
    }
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        this.showModal = false;
      }
    });
  },
  methods: {
    loadMoreModal() {
      this.loadMore = !this.loadMore
    },
    showModalMethod(content, pos) {
      this.modalInfo = content
      this.showModal = !this.showModal
      this.rolePosition = pos

    },
    hideModal() {
      this.showModal = false
    },
    setActiveRole(val) {
      //
      document.querySelector("#mobileJobPosting").scrollTop = 0
      // document.getElementById('mobileJobPosting').scrollTop = 0
      this.active_role = val
      // console.log(this.role_label)
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 1020px) and (min-width: 770px) {

}

#mobileJobPosting {
  scroll-behavior: smooth !important;
}

.cards-enter-active, .cards-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.cards-enter-active {
  transition-delay: 0.5s;
}

.cards-enter {
  opacity: 0;
  transform: translateY(-100px);
}

.cards-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.cards-leave {
  opacity: 1;
  transform: translateY(0px);
}

.cards-leave-to {
  opacity: 0;
  transform: translateY(100px);
}


.border-down {
  -webkit-box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.35);
}

.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  /*background-color: #F5F5F5;*/
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: #DFDFDF;
  border-radius: 10px;
  /*outline: 1px solid slategrey;*/
}

#CAREERS {
  scroll-margin-top: 10em;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {

  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.activeJob:after {
  content: '';
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #FAAD14;
  height: 8px;
  border-radius: 30px;
}

.borderTop {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #e5e7eb;
  height: 8px;

}

.borderDuloLeft {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #e5e7eb;
  height: 8px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.borderDuloRight {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #e5e7eb;
  height: 8px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.maxwidth {
  max-width: 50% !important;
}
</style>
