<template>
  <section id="gallery" class="bg-white  ">
    <div class="">
      <!--      -mt-24-->
      <img class="mx-auto lg:block hidden " src="../assets/gallery/Elements.png"/>
      <img class="mx-auto lg:hidden mt-8 " src="../assets/gallery/H5Elements.png"/>
    </div>
    <div class=" overflow-x-auto modalContent">
      <!--      -->
      <!--      -->
      <!--      min-w-[1060px]-->
      <div class="grid-cols-6 lg:gap-0 w-[1800px] mx-auto gap-2 place-content-center overflow-hidden lg:grid hidden">
        <!--        md:min-w-[100%] min-w-[1366px]-->
        <div v-for="(item, index) in slidesImage" v-bind:key="index"
             class="imgx max-w-[299px] max-h-[309px] min-w-[209] min-h-[219]  overflow-hidden"
             @click="openImage(index)">
          <img class="object-cover max-w-[299px] max-h-[309px] min-w-[209] min-h-[219] " :src="item"/>
        </div>
      </div>
      <div class="grid-cols-6 place-items-center overflow-hidden lg:hidden grid min-w-[1080px] ">
        <!--        md:min-w-[100%] min-w-[1366px]-->
        <div v-for="(item, index) in slidesImage" v-bind:key="index" class="" @click="openImage(index)">
          <img class="object-cover " :src="item"/>
        </div>
      </div>
    </div>
    <transition name="modal">
      <div v-if="showModal" class="modal-mask">
        <div class="modal-wrapper modal-container">
          <div @click="closeImage"
               class="cursor-pointer z-[9999]  absolute xl:right-[35vh] right-[5vh] lg:top-[20vh] sm:top-[27vh] top-[20vh]  w-[40px] ">
            <img class="" src="../assets/gallery/eva_close-fill-1.png" alt="photo">
          </div>
          <div class="flex justify-center relative ">
            <img
                class="object-cover rounded-2xl lg:h-[50%] h-[250px] lg:w-[100%] w-[300px] lg:max-w-[750px] max-h-[450px]"
                :src="slidesImageModal[current].imgSrc" @click.stop=""/>
            <div
                class="absolute xl:top-[50%] top-[120%] lg:left-[36vh] left-[35%] text-4xl cursor-pointer text-white leftArrow"
                aria-label="Previous slide" @click="slideXC(-1)">
              <img class="w-[60%]" src="../assets/title-bar.png"/>
            </div>
            <div
                class="absolute xl:top-[50%] top-[120%] lg:right-[36vh] right-[35%] text-4xl cursor-pointer text-white rightArrow"
                aria-label="Next slide" @click="slideXC(1)">
              <img class="rotate-180 w-[60%]" src="../assets/title-bar.png"/>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>
<script>
// import SLLogo from "../assets/SLLogo.png"
// <carousel
//     :centerMode="true"
// :perPage="1"
// :paginationEnabled="false"
// :autoplay="true"
// :autoplayTimeout="2000"
// :loop="true"
// :navigationEnabled="true"
// navigationNextLabel=">"
// navigationPrevLabel="<"
// easing="ease-out"
// class="px-[500px]"
// :navigateTo="startNum"
//     >
//     <slide class="flex justify-center items-center">
//     <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/1_modal.jpg"/>
//     </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover  rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/2_modal.jpeg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover  rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/3_modal.jpg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover  rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/4_modal.png"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/5_modal.jpg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover  rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/6_modal.jpg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/7_modal.jpeg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/8_modal.jpeg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/9_modal.jpg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/10_modal.jpg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/11_modal.jpeg"/>
// </slide>
// <slide class="flex justify-center items-center">
//   <img class="object-cover rounded-2xl h-[50%] w-[100%]" src="../assets/gallery/12_modal.jpg"/>
// </slide>
// </carousel>

import img_1 from "../assets/gallery/1.png"
import img_2 from "../assets/gallery/2.png"
import img_3 from "../assets/gallery/3.png"
import img_4 from "../assets/gallery/4.png"
import img_5 from "../assets/gallery/5.png"
import img_6 from "../assets/gallery/6.png"
import img_7 from "../assets/gallery/7.png"
import img_8 from "../assets/gallery/8.png"
import img_9 from "../assets/gallery/9.png"
import img_10 from "../assets/gallery/10.png"
import img_11 from "../assets/gallery/11.png"
import img_12 from "../assets/gallery/12.png"

import img_1_modal from "../assets/gallery/1_modal.jpg"
import img_2_modal from "../assets/gallery/2_modal.jpg"
import img_3_modal from "../assets/gallery/3_modal.jpg"
import img_4_modal from "../assets/gallery/4_modal.jpg"
import img_5_modal from "../assets/gallery/5_modal.jpg"
import img_6_modal from "../assets/gallery/6_modal.jpg"
import img_7_modal from "../assets/gallery/7_modal.jpg"
import img_8_modal from "../assets/gallery/8_modal.jpg"
import img_9_modal from "../assets/gallery/9_modal.jpg"
import img_10_modal from "../assets/gallery/10_modal.jpg"
import img_11_modal from "../assets/gallery/11_modal.jpg"
import img_12_modal from "../assets/gallery/12_modal.jpg"

export default {
  name: "PhotoGallery",
  data: function () {
    return {
      showModal: false,
      startNum: 0,
      current: 0,
      slidesImageModal: [
        {imgSrc: img_1_modal},
        {imgSrc: img_2_modal},
        {imgSrc: img_3_modal},
        {imgSrc: img_4_modal},
        {imgSrc: img_5_modal},
        {imgSrc: img_6_modal},
        {imgSrc: img_7_modal},
        {imgSrc: img_8_modal},
        {imgSrc: img_9_modal},
        {imgSrc: img_10_modal},
        {imgSrc: img_11_modal},
        {imgSrc: img_12_modal},
      ],
      slidesImage: [
        img_1,
        img_2,
        img_3,
        img_4,
        img_5,
        img_6,
        img_7,
        img_8,
        img_9,
        img_10,
        img_11,
        img_12
      ]
    }
  },
  methods: {
    openImage(val) {
      this.showModal = true
      this.current = val
    },
    closeImage() {
      this.showModal = false
    },
    slideXC(dir) {
      var len = this.slidesImageModal.length;
      this.current = (this.current + dir % len + len) % len;

    }
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        this.showModal = false;

      }
      if (e.key == 'ArrowLeft') {
        this.slideXC(-1)
      }
      if (e.key == 'ArrowRight') {
        this.slideXC(1)
      }
    });
  },
};
</script>
<style scoped>
@media screen and (max-width: 1490px) and (min-width: 1285px) {
  .leftArrow {
    left: 25vh !important;
  }

  .rightArrow {
    right: 25vh !important;
  }
}

.modalContent::-webkit-scrollbar {
  height: 6px;

}

.modalContent::-webkit-scrollbar-track {
  overflox-x: overlay;
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  /*background-color: #000000;*/
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: #555;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*outline: 1px solid slategrey;*/
}


.imgx:hover img {
  transform: scale(1.5);
}

.imgx img {
  transition: transform .5s ease;
}


.imgTest {
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
}

.imgTest:hover img {
  width: 300px;
  height: 300px;
}


.modal-mask {
  position: fixed;
  z-index: 9993;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: all 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;

}

.modal-container {
  transition: all 0.3s ease;

}

.VueCarousel-navigation-prev {
  margin-left: 80px !important;
  color: white !important;
  outline: none !important;
  font-size: 60px;
  /*transform: scale(1, 1.7) !important;*/
}

.VueCarousel-navigation-next {
  margin-right: 80px !important;
  outline: none !important;
  color: white !important;
  font-size: 60px;
  /*transform: scale(1, 1.7) !important;*/
}

.modal-enter .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);

}


.modal-leave-active .modal-container {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
</style>
